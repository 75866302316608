import React, { useEffect, useState, useCallback, useRef } from 'react';
import Meta from '../components/Meta';
import { Link } from 'react-router-dom';
import VideoJS from '../components/VideoPlayer';
import { TagAds, BannerAds, GridAds } from '../components/AdsComponent';
import ErrorRetry, { LoadingText } from '../components/ErrorRetry';
import axios from 'axios';
import { LazyImage, placeholder, errorPlaceholder } from '../components/LazyImage';

const DetailVideo = ({ data, relation }) => {
  const { id, title, litpic, playurl, typename, tag, body, source } = data;
  const [fullplayurl, setFullPlayUrl] = useState(null);
  const [tags, setTags] = useState([]);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [fileId, setFileId] = useState('');
  const playerRef = useRef(null);

  // **获取视频播放 URL**
  const fetchUrlsAndTest = useCallback(async () => {
    setLoading(true);
    setError(false);

    try {
      const response = await axios.get('/c.json');
      const urlPrefixes = source === 1 ? response.data.playurl2 : response.data.playurl;

      if (!Array.isArray(urlPrefixes) || urlPrefixes.length === 0) {
        throw new Error("No valid URLs found");
      }

      setTags(tag ? tag.split(',').map(t => t.trim()) : []);

      // const controller = new AbortController();
      // // **使用 Promise.any() 选择第一个可用的 URL**
      // const testRequests = urlPrefixes.map(prefix =>
      //   axios.get(prefix + playurl, { signal: controller.signal })
      //     .then(() => prefix + playurl)
      //     .catch(err => {
      //       if (err.name === "CanceledError") {
      //         // 请求已被取消，不需要处理
      //         return new Promise(() => {}); // 返回一个永远不会解决的 Promise
      //       }
      //       throw err; // 继续抛出其他错误
      //     })
      // );
      const randomIndex = Math.floor(Math.random() * urlPrefixes.length);
      const selectedUrl = urlPrefixes[randomIndex] + playurl;
      // 获取第一个成功的请求
      setFullPlayUrl(selectedUrl);

      // **解析 fileId**
      const slashIndex = playurl.indexOf('/');
      const extractedFileId = playurl.substring(0, slashIndex);
      setFileId(source === 1 ? `https://8xiy4.xyz/assets/${extractedFileId}.mp4` : `https://5gmtf.xyz/mp4/${extractedFileId}.mp4`);

    } catch (e) {
      console.error('Error fetching Play URL:', e);
      setError(true);
    } finally {
      setLoading(false);
    }
  }, [playurl, source]);

  useEffect(() => {
    fetchUrlsAndTest();

    return () => {
      if (playerRef.current) {
        playerRef.current.dispose();
        playerRef.current = null;
      }
    };
  }, [fetchUrlsAndTest]);

  // **确保 VideoJS 仅在 fullplayurl 存在时渲染**
  const videoJsOptions = fullplayurl
    ? {
        autoplay: false,
        controls: true,
        responsive: true,
        poster: litpic,
        fluid: true,
        sources: [{ src: fullplayurl, type: 'application/x-mpegURL' }],
      }
    : null;

  const handlePlayerReady = (player) => {
    if (playerRef.current) {
      playerRef.current.dispose();
    }
    playerRef.current = player;

    player.on('error', () => {
      console.error('player error:', player.error().message);
    });
  };

  if (loading) return <LoadingText />;
  if (error) return <ErrorRetry title="视频" onClick={fetchUrlsAndTest} />;

  return (
    <>
      <Meta title={`${title || '精彩视频'}-3B`} description={`在线观看视频-${title || '精彩视频'}`} keywords={`3B,${title || '精彩视频'},${tag || ''}`} />
      <BannerAds />
      <GridAds />

      <div className="title bg-sc text-tb p-2 md:p-4 text-left mb-4">
        <Link to="/">首页</Link> &gt; <Link to={`/h/${typename}`}>{typename}</Link>
      </div>

      {videoJsOptions && <VideoJS key={fullplayurl} options={videoJsOptions} onReady={handlePlayerReady} />}
      {!videoJsOptions && <div className="max-w-[640px] mx-auto">抱歉，视频已下架</div>}

      <div className="announce bg-tb w-full p-4 md:p-6 text-center mb-4">{title}</div>
      {body && <div className="w-full">{body}</div>}

      <div>
      <div className="px-2 my-4 tags" >相关标签</div>
       {tags.map((t, index) => (
          <Link key={index} to={`/tags/${t}/`} className="tags" style={{color:'black',background:'#ff9900'}}>{t}</Link>
        ))}
      </div>
  
      <div style={{marginTop:'8px'}}>
        <div className="px-2 my-4 tags" >推荐广告</div>
        <TagAds />
      </div>
      <div className="tags_wall flex flex-wrap px-4 mb-6 justify-center gap-1 xs:gap-1 xs:text-xs s:text-sm" style={{marginTop:'8px'}}>
        <a href={fileId} className="border border-tw text-tw px-2 py-1 text-center grow shrink basis-auto">下载本片</a>
        <a href="https://x33gbuz.com" className="border border-tw text-tw px-2 py-1 text-center grow shrink basis-auto">地址找回</a>
      </div>

      <div className="title bg-sc text-tb p-2 md:p-4 text-left mb-4 mt-4">推荐视频</div>
      <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mb-4">
        {relation.map((item) => (
          <VideoCard key={item.id} item={item} />
        ))}
      </div>
    </>
  );
};

// **推荐视频卡片组件**
const VideoCard = ({ item }) => (
  <div className="break-inside-avoid mb-4">
    <Link to={`/${item.id}/`} className="block">
      <LazyImage
        className="w-full h-auto object-cover"
        src={item.litpic}
        alt={item.title}
        placeholder={placeholder(300, 200)}
        errorPlaceholder={errorPlaceholder(300, 200, item.title)}
        width={300}
        height={200}
      />
      <p className="text-center mt-2">{item.title}</p>
    </Link>
  </div>
);

export default DetailVideo;
